import React from 'react';

import Image from '~/components/Image';
import Button from '~/components/Button';
import styles from './Hero.module.scss';

type Props = { title: string; text: string; icon: string; isSuccess?: boolean };

const Hero: React.FC<Props> = ({ title, text, icon, isSuccess }) => {
  return (
    <div className={styles.hero}>
      <Image img={icon} alt={title} className={styles.image} />
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
      {isSuccess && (
        <Button className={styles.btn} as={{ tag: 'Link', to: '/' }}>
          Back to main page
        </Button>
      )}
    </div>
  );
};

export default Hero;
