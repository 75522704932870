export enum ProjectType {
  IOS_APP = 'iosApp',
  ANDROID_APP = 'androidApp',
  WEB_APP = 'webApp',
  BACKEND_APP = 'backendApp',
  UX = 'ux',
  WEBSITE = 'website',
  ADA_COMPLIANCE = 'adaCompliance',
  SOMETHING_ELSE = 'somethingElse',
}

export enum ErrorMessage {
  required = 'This field is required',
  invalidFullName = 'Enter a full name',
  invalidEmail = 'Enter a valid e-mail',
  noProjectDetails = 'Enter a project details',
}
