import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Styles } from 'react-select';
import styles from '~/pages/estimate-project/EstimateProject.module.scss';
import { SelectDTO } from '~/pages/estimate-project/types';
import Select from '~/components/Select';

const customStyles: Styles = {
  option: (_, state) => {
    const background = state.isFocused ? '#f0f0f0' : '#fff';

    return { background, padding: '12px', fontSize: '18px' };
  },
  container: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  indicatorsContainer: () => ({
    padding: '0',
    display: 'none',
  }),
  menu: () => ({
    zIndex: 1,
    position: 'absolute' as 'absolute',
    marginTop: '0',
    width: 'calc(100% + 16px)',
    top: 'calc(100% - 1px)',
    left: '-8px',
    padding: '0 8px 8px 8px',
    overflow: 'hidden',
  }),
  menuList: () => ({
    boxShadow:
      '0 3px 8px 0 rgba(0, 0, 0, 0.2), inset 0 -1px 0 0.5px rgba(0, 0, 0, 0.1)',
  }),
  input: () => ({
    padding: 0,
    margin: 0,
    height: '20px',
  }),
};

const Budget: React.FC<{ budget: SelectDTO[] }> = ({ budget }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name='budget'
      as={
        <Select
          className={styles.field}
          options={budget}
          styles={customStyles}
          placeholder=''
          isSearchable={false}
          onMenuOpen={() => {
            setValue('budget', null);
            setMenuIsOpen(true);
          }}
          onMenuClose={() => setMenuIsOpen(false)}
          menuIsOpen={menuIsOpen}
          label="What's your budget? (optional)"
        />
      }
      control={control}
    />
  );
};
export default Budget;
