import React, { RefObject } from 'react';
import classNames from 'classnames';
import ReactSelect, { Props as ReactSelectProps, Styles } from 'react-select';

import Label from '~/components/Label';
import styles from './Select.module.scss';
import { ReactComponent as ErrorIcon } from '~/assets/icons/error-red.svg';
import { ReactComponent as ChevronDownIcon } from '~/assets/icons/chevron-down.svg';
import { InputStatus } from '~/components/Input';
import isEmpty from 'lodash/isEmpty';

type Props = ReactSelectProps & {
  label: string;
  status?: InputStatus;
  statusMessage?: string;
  innerRef?: RefObject<ReactSelect>;
};

const Select: React.FC<Props> = ({
  label,
  value,
  styles: customStyles,
  name,
  className,
  status,
  statusMessage,
  innerRef,
  ...restProps
}) => {
  const notEmpty = !isEmpty(value)
    ? {
        paddingTop: '20px',
        paddingBottom: '4px',
      }
    : {};
  const invalid = status === 'invalid' && {
    background: 'rgba(255, 0, 43, 0.06)',
    borderColor: '#ff002b',
  };

  const selectStyles: Styles = {
    ...customStyles,
    control: (_, state) => {
      const focused = state.isFocused && {
        outline: '3px solid #74b7fd',
        borderColor: 'transparent',
      };

      return {
        width: '100%',
        padding: '12px',
        fontSize: '18px',
        border: '1px solid #eaeaf0',
        display: 'flex',
        minHeight: '49px',
        marginBottom: status === 'invalid' ? '0px' : '20px',
        ...notEmpty,
        ...invalid,
        ...focused,
      };
    },
  };

  return (
    <div className={classNames(styles.root, className)}>
      <ReactSelect
        styles={selectStyles}
        value={value}
        ref={innerRef}
        {...restProps}
      />

      {label && (
        <Label
          text={label}
          id={name}
          focused={!isEmpty(value)}
          invalid={status === 'invalid'}
          labelClass={styles.selectLabel}
        />
      )}

      <ChevronDownIcon
        className={classNames(styles.arrow, {
          [styles.rotated]: restProps.menuIsOpen,
        })}
      />

      {status === 'invalid' ? (
        <p className={styles.status}>
          <ErrorIcon className={styles.icon} />
          {statusMessage}
        </p>
      ) : null}
    </div>
  );
};
export default Select;
