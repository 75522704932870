import { AxiosError } from 'axios';

import { RequestService } from '~/services/Request';
import { ESTIMATION_ENDPOINT } from '~/constants';

import { EstimationFormValues, SelectDTO } from '../../types';

const useSubmit = (
  onSubmitSucceed: () => void,
  onSubmitFailed: (error: AxiosError) => void,
) => {
  const submit = (data: EstimationFormValues) => {
    const { type, files, details, budget, email, fullName, phone } = data;
    const body = new FormData();

    type.forEach(({ value }: SelectDTO) => {
      body.append('type', value);
    });
    files.forEach((file: File) => {
      body.append('attachments', file, file.name);
    });
    body.append('budget', budget ? budget.value : '');
    body.append('details', details);
    body.append('email', email);
    body.append('fullName', fullName);
    body.append('phone', phone);

    return RequestService.client
      .post(ESTIMATION_ENDPOINT, body)
      .then(() => {
        onSubmitSucceed();
      })
      .catch((error: AxiosError) => {
        onSubmitFailed(error);
      });
  };

  return {
    submit,
  };
};

export default useSubmit;
