import { EstimateProjectDTO } from '~/pages/estimate-project/types';
import metadata from './metadata';
import estimate from './assets/estimate.png';
import envelop from './assets/envelope-big.svg';

const estimateProject: EstimateProjectDTO = {
  metadata: metadata,
  pageTitle: 'Estimate your project',
  title: 'Estimate your project',
  successTitle: 'Your estimation request has been sent',
  text:
    'If you have any other questions or want to set up a call and say hi, e-mail us at <a href="mailto:hello@swing.dev">hello@swing.dev</a>',
  icon: estimate,
  successIcon: envelop,
  submit: 'SEND TO ESTIMATE',
  budget: [
    { value: '$12,000 - $25,000', label: '$12,000 - $25,000' },
    { value: '$25,000 - $50,000', label: '$25,000 - $50,000' },
    { value: '$50,000 - $80,000', label: '$50,000 - $80,000' },
    { value: '$80,000 +', label: '$80,000 +' },
  ],
  types: [
    {
      label: 'iOS App',
      value: 'iOS App',
    },
    {
      label: 'UX or Visual Design',
      value: 'UX or Visual Design',
    },

    {
      label: 'Android App',
      value: 'Android App',
    },
    {
      label: 'Website',
      value: 'Website',
    },
    {
      label: 'Web App',
      value: 'Web App',
    },
    {
      label: 'ADA Compliance',
      value: 'ADA Compliance',
    },
    {
      label: 'Backend App',
      value: 'Backend App',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
};

export default estimateProject;
