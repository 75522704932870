import React, { useEffect, useState, useRef } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { AxiosError } from 'axios';
import ReactSelect from 'react-select';

import { ReactComponent as ErrorIcon } from '~/assets/icons/error-orange.svg';
import Input from '~/components/Input';
import styles from './Form.module.scss';
import { EstimateProjectDTO, EstimationFormValues } from './../../types';
import useSubmit from '~/pages/estimate-project/hooks/useSubmit';
import UploadDocumentsComponent from '~/pages/estimate-project/components/UploadDocuments';
import Button from '~/components/Button';
import { ErrorMessage } from '~/pages/estimate-project/enums';

import { AnalyticsService } from '~/services/Analytics';
import { ValidationService } from '~/services/Validation';

import Budget from './../Budget';
import Type from './../Type';

interface Props {
  estimateData: EstimateProjectDTO;
  onSuccess: () => void;
}

const scrollTo = (element: HTMLElement | null) =>
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' });

const Form: React.FC<Props> = ({ estimateData, onSuccess }) => {
  const [hasStartedFilling, setHasStartedFilling] = useState<boolean>(false);
  const [sendingFailed, setSendingFailed] = useState<boolean>(false);
  const nameField = useRef<HTMLInputElement | null>(null);
  const emailField = useRef<HTMLInputElement | null>(null);
  const typeField = useRef<ReactSelect | null>(null);
  const detailsField = useRef<HTMLTextAreaElement | null>(null);
  const errorBox = useRef<HTMLDivElement>(null);

  const form = useForm<EstimationFormValues>({
    mode: 'onBlur',
    defaultValues: {
      fullName: '',
      email: '',
      phone: '',
      type: [],
      details: '',
      budget: {},
      files: [],
    },
  });

  const {
    handleSubmit,
    control,
    errors,
    formState: { submitCount, isSubmitting, isDirty },
  } = form;

  useEffect(() => {
    if (!hasStartedFilling && isDirty) {
      setHasStartedFilling(true);

      AnalyticsService.track({
        category: 'Estimate Project Form',
        action: 'Start Filling',
      });
    }
  }, [hasStartedFilling, isDirty]);

  const onSubmitSuccess = () => {
    AnalyticsService.track({
      category: 'Estimate Project Form',
      action: 'Send With Success',
    });

    onSuccess();
  };

  const onSubmitFailed = (error: AxiosError) => {
    AnalyticsService.track({
      category: 'Estimate Project Form',
      action: 'Send With Error',
      label: error.response?.statusText,
    });

    setSendingFailed(true);
  };

  const { submit } = useSubmit(onSubmitSuccess, onSubmitFailed);

  useEffect(() => {
    if (errors.fullName) {
      scrollTo(nameField.current);
    } else if (errors.email) {
      scrollTo(emailField.current);
    } else if (errors.type) {
      const inputRef = typeField.current?.select.inputRef;
      inputRef instanceof HTMLElement && scrollTo(inputRef);
    } else if (errors.details) {
      scrollTo(detailsField.current);
    } else if (sendingFailed) {
      scrollTo(errorBox.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, sendingFailed]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          handleSubmit(submit)(e);
          setSendingFailed(false);
        }}
        className={styles.form}
      >
        <Controller
          name='fullName'
          render={({ onChange, onBlur, value }) => (
            <Input
              label='Full name'
              className={styles.field}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              status={errors.fullName ? 'invalid' : 'valid'}
              statusMessage={
                errors.fullName ? ErrorMessage.invalidFullName : undefined
              }
              ref={nameField}
              autoComplete='name'
            />
          )}
          rules={{ required: true, minLength: 3 }}
        />
        <Controller
          name='email'
          render={({ onChange, onBlur, value }) => (
            <Input
              label='E-mail'
              className={styles.field}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              status={errors.email ? 'invalid' : 'valid'}
              statusMessage={
                errors.email ? ErrorMessage.invalidEmail : undefined
              }
              ref={emailField}
            />
          )}
          rules={{ validate: ValidationService.isValidEmail }}
        />

        <Controller
          name='phone'
          as={
            <Input className={styles.field} label='Phone number (optional)' />
          }
        />

        <Budget budget={estimateData.budget} />
        <Type types={estimateData.types} innerRef={typeField} />

        <Controller
          control={control}
          name='details'
          render={({ onChange, onBlur, value }) => (
            <Input
              label='Project details'
              className={styles.textarea}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              useTextarea
              status={errors.details ? 'invalid' : 'valid'}
              statusMessage={
                errors.details ? ErrorMessage.noProjectDetails : undefined
              }
              ref={detailsField}
            />
          )}
          rules={{ required: true, minLength: 1 }}
        />

        <UploadDocumentsComponent />
        <div className={styles.footer}>
          {sendingFailed && (
            <div className={styles.errorBox} ref={errorBox}>
              <ErrorIcon className={styles.errorIcon} />
              Oops, the form can’t be sent now. Please try again or e-mail us at{' '}
              <a href='mailto:hello@swing.dev'>hello@swing.dev</a>
            </div>
          )}

          <Button
            className={styles.submit}
            isLoading={isSubmitting}
            as={{ tag: 'button', type: 'submit' }}
          >
            {estimateData.submit}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
