import React, { useState, RefObject } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect, { Styles } from 'react-select';

import styles from '~/pages/estimate-project/EstimateProject.module.scss';
import checkIcon from './assets/check-white.svg';
import { SelectDTO } from '~/pages/estimate-project/types';
import Select from '~/components/Select';
import { ValidationService } from '~/services/Validation';
import { ErrorMessage } from '~/pages/estimate-project/enums';

const customStyles: Styles = {
  option: (_, state) => {
    const background = state.isFocused ? '#f0f0f0' : '#fff';
    const isSelected = state.isSelected
      ? {
          background: '#ff002b',
          backgroundImage: `url(${checkIcon})`,
          backgroundPositionX: '-1px',
          backgroundPositionY: '-1px',
        }
      : {
          background: 'white',
        };

    return {
      background,
      padding: '12px',
      fontSize: '18px',
      display: 'flex',
      width: '50%',
      '&:before': {
        content: '""',
        display: 'block',
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        border: 'solid 1px rgba(0, 0, 0, 0.2)',
        marginRight: '12px',
        ...isSelected,
      },
      '@media only screen and (max-width: 500px)': {
        width: '100%',
      },
    };
  },
  container: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '18px',
    padding: '0',
  }),
  indicatorsContainer: () => ({
    padding: '0',
    display: 'none',
  }),
  control: () => ({
    width: '100%',
    padding: '12px',
    fontSize: '18px',
    border: '1px solid #eaeaf0',
    display: 'flex',
  }),
  menu: () => ({
    zIndex: 1,
    position: 'absolute' as 'absolute',
    marginTop: '0',
    width: 'calc(100% + 16px)',
    top: 'calc(100% - 1px)',
    left: '-8px',
    padding: '0 8px 8px 8px',
    overflow: 'hidden',
  }),
  menuList: () => ({
    boxShadow:
      '0 3px 8px 0 rgba(0, 0, 0, 0.2), inset 0 -1px 0 0.5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer',
  }),
  input: () => ({
    padding: 0,
    margin: 0,
    height: '20px',
  }),
  multiValue: () => ({
    display: 'inline-flex',
    background: 'transparent',
    marginRight: '4px',
    '&:not(:last-of-type):after': {
      content: '","',
    },
  }),
  multiValueLabel: () => ({}),
};

const Type: React.FC<{
  types: SelectDTO[];
  innerRef?: RefObject<ReactSelect>;
}> = ({ types, innerRef }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { control, errors } = useFormContext();
  const MultiValueRemove = () => null;

  return (
    <Controller
      name='type'
      as={
        <Select
          className={styles.typeField}
          options={types}
          styles={customStyles}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{
            MultiValueRemove,
          }}
          isMulti
          isSearchable={false}
          placeholder=''
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          menuIsOpen={menuIsOpen}
          label='What do you need help with?'
          status={errors.type ? 'invalid' : 'valid'}
          statusMessage={errors.type ? ErrorMessage.required : undefined}
        />
      }
      innerRef={innerRef}
      control={control}
      rules={{ validate: ValidationService.hasArrayAtLeastOneValue }}
    />
  );
};
export default Type;
